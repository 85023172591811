import { subject } from '@casl/ability';
import { Can } from 'utils/Can';
import { type CurrentUser, useCurrentUser } from 'hooks/useCurrentUser';
import { PageNotFound } from 'pages/PageNotFound/PageNotFound';
import { Navigate, useMatch, useParams } from 'react-router';
import { CenteredPageLoadingCarrot } from 'turnip/LoadingCarrot/LoadingCarrot';
import { PageWithAuthenticatedNavs } from 'turnip/PageWithAuthenticatedNavs/PageWithAuthenticatedNavs';
import { loginUrl, routes } from 'utils/carrotUrls';
import { useCurrentUrl } from 'utils/useCurrentUrl';

/**
 * If the user is logged out, redirect them to log in. If the user is logged
 * in, restrict access to the page based on their account status and
 * permissions.
 */
export function AuthorizedPage({ children }: { children: React.ReactNode }) {
  const { data } = useCurrentUser();
  const { siteId } = useParams();
  const currentUrl = useCurrentUrl();

  if (!data?.user) {
    window.location.assign(loginUrl(currentUrl));
    return <CenteredPageLoadingCarrot title="Redirecting to login page" />;
  }

  return (
    <RestrictByAccountStatus user={data.user}>
      {siteId ? (
        <RestrictBySiteAccess siteId={siteId}>{children}</RestrictBySiteAccess>
      ) : (
        children
      )}
    </RestrictByAccountStatus>
  );
}

/**
 * Render the provided children if the active account has the necessary account status.
 */
function RestrictByAccountStatus({
  user: { superAdmin, activeAccount },
  children,
}: {
  user: CurrentUser;
  children: React.ReactNode;
}) {
  // Accounts without an active subscription have restricted access.
  const carrotAccountStatus = activeAccount?.owner.carrotAccountStatus;
  const userHasAllowedStatus = !carrotAccountStatus
    ? false
    : ['active', 'active_past_due', 'trialing'].includes(carrotAccountStatus);

  /**
   * Certain routes are exceptions that should be accessible to all users,
   * however, regardless of if they have an allowed status.
   *
   * The billing route is allowed because a) all current and former users
   * should be able to access their old invoices, etc., and b) we send users
   * who need to reactivate or resolve other account problems there.
   *
   * The onboarding pages are allowed because a) it should be harmless, and b)
   * sometimes subscription data hasn't been synced yet after sign-up
   * (typically for bundle sign-ups). This caused a bug where the user was
   * redirected to the billing home page instead of being sent to the
   * onboarding flow.
   *
   * The switch account route is allowed so that users can change their active
   * account even if their current active account doesn't have an allowed
   * status.
   */
  const billingRouteMatch = useMatch(routes().billingHome);
  const onboardingRouteMatch = useMatch({ path: '/onboarding/', end: false });
  const switchAccountRouteMatch = useMatch({
    path: '/account/switch/:accountId',
    end: false,
  });
  const onExceptionRoute =
    billingRouteMatch || onboardingRouteMatch || switchAccountRouteMatch;

  // Redirect users who lack an allowed account status and are not accessing
  // one of the exception routes to the billing page.
  if (!superAdmin && !userHasAllowedStatus && !onExceptionRoute) {
    return <Navigate to={routes().billingHome} />;
  }

  return <>{children}</>;
}

function RestrictBySiteAccess({
  siteId,
  children,
}: {
  siteId: string;
  children: React.ReactNode;
}) {
  const wpBlog = subject('WpBlog', {
    wordpress_blog_id: parseInt(siteId),
  });

  return (
    <>
      <Can I="read" this={wpBlog}>
        {children}
      </Can>

      <Can not I="read" this={wpBlog}>
        <PageWithAuthenticatedNavs>
          <PageNotFound />
        </PageWithAuthenticatedNavs>
      </Can>
    </>
  );
}
