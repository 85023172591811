import { graphql } from 'generated/gql/account-manager';
import { useAmQuery } from 'graphql/useAmQuery';
import { useParams } from 'react-router';
import { useCurrentUser } from './useCurrentUser';
import { useJwt, userDataFromToken } from './useJwt';

const teamMemberAbilitiesQueryDocument = graphql(`
  query teamMemberAbilities(
    $wordpressUserId: Int
    $accountId: Int
    $wordpressBlogId: Int
  ) {
    teamMemberAbility(
      wordpressUserId: $wordpressUserId
      accountId: $accountId
      wordpressBlogId: $wordpressBlogId
    ) {
      action
      subject
      limitingIds
    }
  }
`);

export function useTeamMemberAbilities() {
  const { accountId, siteId } = useParams();
  // TODO: remove fallback to active account when we have eliminated the
  // concept of an active account
  const userQuery = useCurrentUser();
  const accountIdWithFallback = siteId
    ? undefined
    : (accountId ?? userQuery.data?.user?.activeAccount?.id);

  const { data } = useJwt();
  const wordpressUserId = userDataFromToken(data?.token)?.id;

  const hasRequiredQueryVariables = Boolean(
    wordpressUserId && (accountIdWithFallback || siteId)
  );

  const query = useAmQuery(
    teamMemberAbilitiesQueryDocument,
    {
      wordpressUserId: Number(wordpressUserId),
      accountId: Number(accountIdWithFallback),
      wordpressBlogId: Number(siteId),
    },
    {
      // Only enable the query if the required variables are present and the
      // query has not already failed. This helps prevent loops.
      enabled: (query) => hasRequiredQueryVariables && !query.state.error,
      // Don't retry if the query failed. Retrying won't resolve the problem,
      // and could currently cause a loop.
      retry: (_failureCount, error) => !error,
    }
  );

  return query;
}
