import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
  skipToken,
  useQuery,
  UseQueryResult,
  type SkipToken,
  type UndefinedInitialDataOptions,
} from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { accountManagerGraphQlUrl, useAuthorizationHeader } from './helpers';

export function useAmQuery<TResult, TVariables, TSelectedData = TResult>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables: TVariables | SkipToken,
  options?: Omit<
    UndefinedInitialDataOptions<TResult>,
    'queryKey' | 'queryFn' | 'select'
  > & { select?: (data: TResult) => TSelectedData },
  requestConfiguration: GraphQLClient['requestConfig'] = {}
): UseQueryResult<TSelectedData> {
  const requestHeaders = useAuthorizationHeader();
  const graphQlClient = new GraphQLClient(
    accountManagerGraphQlUrl,
    requestConfiguration
  );

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (document.definitions[0] as any).name.value,
      variables,
      requestHeaders,
    ],
    queryFn:
      variables === skipToken
        ? skipToken
        : async () =>
            graphQlClient.request({
              document,
              variables: variables ?? undefined,
              requestHeaders,
            }),
    ...options,
  });
}
