import { carrotDomain } from './carrotDomain';

const carrotUrl = `https://${carrotDomain()}`;
const amuiUrl = `https://my.${carrotDomain()}`;
const appUrl = `https://app.${carrotDomain()}`;
const helpUrl = `https://help.${carrotDomain()}`;
const communityUrl = `https://community.${carrotDomain()}`;
const supportUrl = `${helpUrl}/support/`;
const pricingUrl = `${carrotUrl}/pricing/`;
const termsUrl = `${carrotUrl}/terms/`;
const privacyPolicyUrl = `${carrotUrl}/privacy/`;
const goUrl = `https://go.${carrotDomain()}`;
const marketplaceUrl = `https://marketplace.${carrotDomain()}`;
const superAdminUrl = `https://admin.${carrotDomain()}`;
const contentUrl = `https://content.${carrotDomain()}`;
const carrotCrmUrl = `https://crm.${carrotDomain()}`;

const authenticationEndpoint = `${appUrl}/wp-json/carrot/v1/auth-api/`;

function loginUrl(redirectUrl?: string) {
  if (redirectUrl) {
    return `${appUrl}/?redirect_to=${encodeURIComponent(redirectUrl)}`;
  }

  return appUrl;
}

function deepLinkToApp(path: string) {
  if (!path.startsWith('/')) throw new Error('path must start with /');
  return `${appUrl}/goto/?t=${appUrl}/account/site/*(ID)*${path}`;
}

/** @deprecated migrate to upgradePath */
function upsellPath(product: string) {
  return `/account/billing/edit?product=${product}`;
}

function upgradePath(product: string, returnUrl: `https://${string}`) {
  return `/account/billing/upgrade?product=${product}&returnUrl=${encodeURIComponent(returnUrl)}`;
}

/**
 * @param siteId - WordPress site ID
 * @param path - Single site app path and query params, if any
 * @returns URL to single site app page
 */
function singleSiteAppUrl(siteId: number, path = '') {
  const pathWithLeadingSlash = path.replace(/^([^/])/, '/$1');
  return `${appUrl}/account/site/${siteId}${pathWithLeadingSlash}`;
}

/**
 * @param domain - WordPress site domain
 * @param path - WP Admin page URL with query params, if any
 * @returns URL to WP Admin page
 */
function wpAdminUrl(domain: string, path = '') {
  return `https://${domain}/wp-admin/${path}`.replace(
    'wp-admin//',
    'wp-admin/'
  );
}

/**
 * @param id - ID used in route (e.g., WordPress blog ID)
 * @returns Object with URLs to the AMUI routes for the given site
 */
function routes(id?: number) {
  return {
    /** `/account/billing` */
    billingHome: '/account/billing',
    /** `/account/billing/edit` */
    membershipEditor: '/account/billing/edit',
    /** `/account/carrot-crm` */
    carrotCrmOptIn: '/account/carrot-crm',
    /** `/account/team-members` */
    teamMembers: '/account/team-members',
    /** `/account/team-members/accept` */
    teamMembersAccept: '/account/team-members/accept',
    /** `/account/team-members/invite` */
    teamMembersInvite: '/account/team-members/invite',
    /** `/account/team-members/edit/:team-member-id` */
    teamMembersEdit: `/account/team-members/edit/${id}`,
    /** `/super/bundles/create` */
    bundlesCreate: '/super/bundles/create',
    /** `/super/bundles` */
    bundlesList: '/super/bundles',
    /** `/account/site/:siteId/analytics` */
    analytics: `/account/site/${id}/analytics`,
    /** `/account/site/:siteId/content-overview` */
    contentOverview: `/account/site/${id}/content-overview`,
    /** `/account/site/:siteId/seo` */
    seo: `/account/site/${id}/seo`,
    /** `/account/site/:siteId/seo/backlink-analysis` */
    backlinkAnalysis: `/account/site/${id}/seo/backlink-analysis`,
    /** `/account/site/:siteId/seo/domain-overview` */
    domainOverview: `/account/site/${id}/seo/domain-overview`,
    /** `/account/site/:siteId/seo/search-performance */
    searchPerformance: `/account/site/${id}/seo/search-performance`,
    /** `/account/site/:siteId/seo/site-audit` */
    siteAudit: `/account/site/${id}/seo/site-audit`,
    /** `/account/switch/:accountId */
    switchAccount: `/account/switch/${id}`,
  };
}

const features = {
  demo: `${carrotUrl}/demo/`,
  seoTools: `${carrotUrl}/features/seo-tools/`,
};

export {
  amuiUrl,
  appUrl,
  authenticationEndpoint,
  carrotCrmUrl,
  carrotUrl,
  communityUrl,
  contentUrl,
  deepLinkToApp,
  features,
  goUrl,
  helpUrl,
  loginUrl,
  marketplaceUrl,
  pricingUrl,
  privacyPolicyUrl,
  routes,
  singleSiteAppUrl,
  superAdminUrl,
  supportUrl,
  termsUrl,
  upgradePath,
  upsellPath,
  wpAdminUrl,
};
