/**
 * WARN:
 * When changing this file, force Chromatic to run all stories by running
 * Chromatic with TurboSnap disabled:
 * `docker compose run node npm run chromatic-all`.
 *
 * Background:
 * Changing any page was causing Chromatic to run the snapshots for all pages
 * because all pages share this common file. This slowed down Chromatic runs
 * and increased our costs.
 *
 * To improve that, we pass this file to the Chromatic `--untraced` flag, which
 * ignores it in the dependency chain used to detect which snapshots to run for
 * which stories.
 *
 * See:
 * - https://www.chromatic.com/docs/turbosnap/setup/#avoid-re-testing-dependent-stories-when-certain-files-changed.
 * - https://www.chromatic.com/docs/configure/#onlychanged
 */

import { Root, RootProps } from 'Root/Root';
import {
  AcceptInvite,
  Analytics,
  BacklinkAnalysis,
  BillingHome,
  CarrotCrmOptIn,
  ContentOverview,
  ContentProMigration,
  CreateAccount,
  CreateAccountComplete,
  DomainOverview,
  KeywordExplorerOrUpsell,
  MarketScout,
  MembershipEditor,
  OnboardingRoutes,
  ScheduleCall,
  SearchPerformance,
  SignUp,
  SiteAuditRoutes,
  SmoothUpgrade,
  SuperAuthorizedPage,
  SuperBundlesCreate,
  SuperBundlesList,
  SwitchAccount,
  TeamMembersRoutes,
} from 'lazy-routes';
import { AuthorizedPage } from 'pages/AuthorizedPage/AuthorizedPage';
import { BaseTrackingScripts } from 'pages/CreateAccount/BaseTrackingScripts';
import { ErrorPage } from 'pages/ErrorPage/ErrorPage';
import { PageNotFound } from 'pages/PageNotFound/PageNotFound';
import { Navigate, Outlet, type RouteObject } from 'react-router';
import { PageWithAuthenticatedNavs } from 'turnip/PageWithAuthenticatedNavs/PageWithAuthenticatedNavs';
import { PageWithFooter } from 'turnip/PageWithFooter/PageWithFooter';

/**
 * This function returns the routes for the app
 *
 * By using a function to add our high-level providers and components to the
 * component tree, we are able to inject test values when routes are rendered
 * during tests.
 */
export const createRoutes = ({
  queryClient,
  mixpanel,
  googleAnalyticsId,
  intercomId,
}: RootProps): RouteObject[] => [
  {
    element: (
      <Root
        queryClient={queryClient}
        mixpanel={mixpanel}
        googleAnalyticsId={googleAnalyticsId}
        intercomId={intercomId}
      >
        <Outlet />
      </Root>
    ),
    errorElement: (
      <Root
        queryClient={queryClient}
        mixpanel={mixpanel}
        googleAnalyticsId={googleAnalyticsId}
        intercomId={intercomId}
      >
        <PageWithFooter>
          <ErrorPage />
        </PageWithFooter>
      </Root>
    ),
    children: appRoutes,
  },
];

const errorRoute: RouteObject = {
  path: 'error-route',
  Component: () => {
    throw new Error('test');
  },
};

const appRoutes: RouteObject[] = [
  /**
   * Sign-up routes
   */
  {
    element: (
      <BaseTrackingScripts>
        <Outlet />
      </BaseTrackingScripts>
    ),
    children: [
      {
        path: 'create-account',
        element: (
          <PageWithFooter>
            <Outlet />
          </PageWithFooter>
        ),
        children: [
          { index: true, element: <CreateAccount /> },
          { path: 'complete', element: <CreateAccountComplete /> },
        ],
      },
      { path: 'sign-up', element: <SignUp /> },
      { path: 'sign-up-bundles', element: <SignUp bundlesVariant /> },
    ],
  },

  /**
   * Accepting an account invitation
   */
  { path: 'account/team-members/accept/:uuid', element: <AcceptInvite /> },

  // Market Scout Report
  { path: 'market-scout', element: <MarketScout /> },

  /**
   * Authorized routes
   */
  {
    element: (
      <PageWithFooter>
        <AuthorizedPage>
          <Outlet />
        </AuthorizedPage>
      </PageWithFooter>
    ),
    children: [
      { path: 'onboarding/*', element: <OnboardingRoutes /> },

      /**
       * Routes with only general nav bar
       */
      {
        element: (
          <PageWithAuthenticatedNavs>
            <Outlet />
          </PageWithAuthenticatedNavs>
        ),
        children: [
          /**
           * When the root is requested, render a 404 for logged-in users and
           * redirect logged-out users to the login page.
           */
          { index: true, element: <PageNotFound /> },

          {
            path: 'account',
            children: [
              {
                path: 'billing',
                children: [
                  { index: true, element: <BillingHome /> },
                  { path: 'edit', element: <MembershipEditor /> },
                  { path: 'upgrade', element: <SmoothUpgrade /> },
                  { path: 'content-pro', element: <ContentProMigration /> },
                ],
              },
              { path: 'carrot-crm', element: <CarrotCrmOptIn /> },
              { path: 'team-members/*', element: <TeamMembersRoutes /> },
              {
                path: 'switch/:accountId',
                element: <SwitchAccount />,
              },
            ],
          },
          { path: 'keyword-explorer', element: <KeywordExplorerOrUpsell /> },
          { path: 'schedule-call', element: <ScheduleCall /> },
        ],
      },

      /**
       * Single-site routes
       */
      {
        path: 'account/site/:siteId',
        element: (
          <PageWithAuthenticatedNavs showSingleSiteNav>
            <Outlet />
          </PageWithAuthenticatedNavs>
        ),
        errorElement: <ErrorPage singleSiteRoute />,
        children: [
          // There is currently no root single-site page, so we render the 404
          // page. This path may be used in the future.
          { index: true, element: <PageNotFound contained={false} /> },
          { path: 'analytics', element: <Analytics /> },
          { path: 'content-overview', element: <ContentOverview /> },
          {
            path: 'seo',
            children: [
              { index: true, element: <Navigate to="domain-overview" /> },
              { path: 'domain-overview', element: <DomainOverview /> },
              { path: 'backlink-analysis', element: <BacklinkAnalysis /> },
              { path: 'search-performance', element: <SearchPerformance /> },
              { path: 'site-audit/*', element: <SiteAuditRoutes /> },
            ],
          },

          // Used for tests:
          errorRoute,
          {
            path: 'test-route',
            element: <div>Authorized single-site route</div>,
          },

          // Using this rather than letting react-router throw a 404 when a
          // single-site route that doesn't exist is requested allows the error
          // element on the single-site routes to catch the error rather than
          // the top-level error element. This means the single-site nav will
          // be rendered, which is a better experience for the user.
          {
            path: '*',
            element: null,
            loader: () => {
              throw new Response('Not found', { status: 404 });
            },
          },
        ],
      },

      /**
       * Superadmin routes
       */
      {
        path: 'super',
        element: (
          <PageWithAuthenticatedNavs>
            <SuperAuthorizedPage>
              <Outlet />
            </SuperAuthorizedPage>
          </PageWithAuthenticatedNavs>
        ),
        children: [
          {
            path: 'bundles',
            children: [
              { index: true, element: <SuperBundlesList /> },
              { path: 'create', element: <SuperBundlesCreate /> },
            ],
          },
          {
            path: 'test-route',
            element: <div>Authorized superadmin route</div>,
          },
        ],
      },

      // Used for tests:
      errorRoute,
      {
        path: 'test-route',
        element: <div>Authorized route</div>,
      },
    ],
  },
];
